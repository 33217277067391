import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less'
import './assets/css/reset.css'
import ElementUI from 'element-ui';
import './assets/iconfont/iconfont.css'

import VideoPlayer from 'vue-video-player'

import 'vue-video-player/src/custom-theme.css';

import 'video.js/dist/video-js.css';

//设置页面关键词

let head = document.getElementsByTagName('head');
console.log('SSSSSSSSSSSSSSS关键词', document.querySelector('meta[name="keywords"]'))
if (document.querySelector('meta[name="keywords"]')) {
  document.querySelector('meta[name="keywords"]').setAttribute('content', '医课堂，中欧医课堂，医课堂电脑端，医院培训')
} else {
  let meta_keyword = document.createElement('meta');
  meta_keyword.setAttribute('name', 'keywords')
  meta_keyword.setAttribute('content', '医课堂，中欧医课堂，医课堂电脑端，医院培训')
  head[0].appendChild(meta_keyword)
}


// require('vue-video-player/node_modules/video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

import videojs from 'video.js'
window.videojs = videojs//要先声明,在下方的hls.js中要用到,不然视频会出错

// require('videojs-contrib-hls/dist/videojs-contrib-hls.js')



Vue.use(VueLazyload, {
  preLoad: 1,//预加载高度
  error: require('@/assets/baise.png'),//错误展示图片
  loading: require('@/assets/baise.png'),//加载等待图片
  attempt: 1,
});
import VueLazyload from 'vue-lazyload'
// a.2 引入element-ui css 样式

import 'element-ui/lib/theme-chalk/index.css';

// a.3 在Vue中注册使用 ElementUI 模块

Vue.use(ElementUI);

// 应用启动时初始化用户信息
store.dispatch('user/InitializeUserInfo');


Vue.config.productionTip = false
Vue.use(less)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

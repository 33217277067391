import {
	login,
	logout,
	getInfo,
	refreshToken
} from '@/api/login'
import {
	getToken,
	setToken,
	setExpiresIn,
	removeToken
} from '@/utils/auth'

const state = {
		token: getToken(),
		name: '',
		nickname: '',
		avatar: '',
		roles: [],
		permissions: [],
		loginDialogVisible: false, // 控制登录对话框的可见性
	}

const mutations = {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_EXPIRES_IN: (state, time) => {
			state.expires_in = time
		},
		SET_NAME: (state, name) => {
			state.name = name
		},
		SET_NICKNAME: (state, nickname) => {
			console.log("SET_NICKNAME = "+nickname)
			state.nickname = nickname
		},
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		SET_PERMISSIONS: (state, permissions) => {
			state.permissions = permissions
		},
		SET_LOGIN_DIALOG_VISIBLE: (state, visible) => {
		  state.loginDialogVisible = visible;
		}
	}

const actions =  {
		// 动态设置登录对话框可见性
		setLoginDialogVisible({ commit }, visible) {
		  commit('SET_LOGIN_DIALOG_VISIBLE', visible);
		},
		// 登录
		/**
		Login({
			commit
		}, userInfo) {
			const username = userInfo.username.trim()
			const password = userInfo.password
			const code = userInfo.code
			const uuid = userInfo.uuid
			return new Promise((resolve, reject) => {
				login(username, password, code, uuid).then(res => {
					let data = res.data
					setToken(data.access_token)
					commit('SET_TOKEN', data.access_token)
					setExpiresIn(data.expires_in)
					commit('SET_EXPIRES_IN', data.expires_in)
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},
		**/
		// 登录
		Login({ commit, dispatch }, userInfo) {
		  const { username, password, code, uuid } = userInfo;
		  return new Promise((resolve, reject) => {
		    login(username.trim(), password, code, uuid).then(res => {
		      let data = res.data;
		      setToken(data.access_token);
		      commit('SET_TOKEN', data.access_token);
		      setExpiresIn(data.expires_in);
		      commit('SET_EXPIRES_IN', data.expires_in);
		
		      // 在这里调用 GetInfo
		      dispatch('GetInfo').then(() => {
		        resolve();
		      }).catch(error => {
		        // 如果获取用户信息失败，则登出并拒绝 Promise
		        dispatch('LogOut');
		        reject(error);
		      });
		    }).catch(error => {
		      reject(error);
		    });
		  });
		},

		// 获取用户信息
		GetInfo({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				getInfo().then(res => {
					const user = res.user
					const avatar = (user.avatar == "" || user.avatar == null) ? require(
						"@/assets/images/profile.jpg") : user.avatar;
					commit('SET_NAME', user.userName)
					commit('SET_NICKNAME', user.nickName)
					commit('SET_AVATAR', avatar)
					console.log("user.nickName 保存到本地localStorage="+user.nickName);
					//add by lsd 20241207 new add
					// 缓存用户信息到 localStorage
					  localStorage.setItem('user_info', JSON.stringify({
						name: user.userName,
						nickname: user.nickName,
						avatar: user.avatar
					  }));
					
					resolve(res)
				}).catch(error => {
					//localStorage.removeItem('user_info');
					reject(error)
				})
			})
		},

		// 刷新token
		RefreshToken({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				refreshToken(state.token).then(res => {
					setExpiresIn(res.data)
					commit('SET_EXPIRES_IN', res.data)
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 退出系统
		LogOut({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				logout(state.token).then(() => {
					commit('SET_TOKEN', '')
					commit('SET_ROLES', [])
					commit('SET_PERMISSIONS', [])
					removeToken()
					localStorage.removeItem('user_info'); // 清除本地缓存
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 初始化时尝试从 localStorage 加载用户信息
		  InitializeUserInfo({ commit }) {
		    const userInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
		    commit('SET_NAME', userInfo.name || '');
		    commit('SET_NICKNAME', userInfo.nickname || '');
		    commit('SET_AVATAR', userInfo.avatar || '');
		  },

		// 前端 登出
		FedLogOut({
			commit
		}) {
			return new Promise(resolve => {
				commit('SET_TOKEN', '')
				removeToken()
				resolve()
			})
		}
	
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
<template>
  <div class="pages">
    <h3>新闻动态</h3>
    <div class="conent">
   
        <div class="font">
          <h2>{{data.title}}</h2>
          <div class="title_time">
            <p>发布时间：2021-11-15 08:51:28 `浏览次数：1263 次</p>
          </div>
          <p v-html="data.content">
            
          </p>
        </div>
      </div>
   
  </div>
</template>
<script>
import {getDetail} from '@/api/news'
export default {
  data(){
    return{
      id:undefined,
      data:null
    }
  },
  created(){
    this.id = String(this.$route.query.id)
    var id = String(this.$route.query.id)
    getDetail({id}).then(res=>{
      this.data = res.data
    })
  },
    updated(){
      console.log(this.id,this.$route.query.id)
      if(this.id != String(this.$route.query.id)){
  var id = String(this.$route.query.id)
    getDetail({id}).then(res=>{
      console.log(res)
    })
      }
  
  },
   watch: {
      '$route' (to, from) {
        this.search(this.$route.query.id)
      }
    },
  mounted(){
     var a= document.getElementsByClassName('el-aside')[0];
      if(this.$route.name =='trends'){
     
    // console.log(a)
       if(this.$route.name =='trends'){
      a.style.display = 'none'
    }else{
      a.style.display = 'block'
      
    }
    }
  },
};
</script>
<style scoped lang='less'>
.pages {
  // width: 1229px;
  background-color: #fff;
  margin-top: -20px;
 
  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  .conent {
     min-height: 450px;
    .font{
      width: 1026px;
      margin: 20px auto;
      .title_time{
        background-color: #F6F5FA;
  p{
    font-size: 15px;
font-family: Source Han Sans CN;
font-weight: 400;
line-height: 26px;
color: #666666;
text-align: center;
  }
      }
      h2{
        font-size: 32px;
font-family: Source Han Sans CN;
font-weight: 500;
line-height: 54px;
color: #000000;
text-align: center;
      }
      p{
        font-size: 22px;
font-family: Source Han Sans CN;
font-weight: 400;
line-height: 37px;
color: #000000;
margin-top: 30px;
      }
    }
  }
}
</style>
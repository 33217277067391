<template>
  <div class="pages">
    <h3>领导关怀</h3>
    <div>
      <h2>领导关怀</h2>
       <div class="lunbo">

      <div class="block">

        <el-carousel :initial-index="currentIndex" @change="changePandT">

          <el-carousel-item

            v-for="(item, index) in img"

            :key="index"

          >

            <img :src="item" alt="" />

          </el-carousel-item>

        </el-carousel>

      </div>

      <div class="textRightBox">

        <!-- <p class="p1">1111111111111111111</p> -->

        <p class="p2">{{ textRight.content }}</p>

      </div>
       </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
         textRight: { title: "", content: "", index: 0 },
      img:['http://oss2.zhongou.org.cn/web/p/k/201610/20161008145707_82007.png','http://oss2.zhongou.org.cn/web/p/k/201610/20161008145826_16654.png','http://oss2.zhongou.org.cn/web/p/k/201610/20161008150136_22731.png','http://oss2.zhongou.org.cn/web/p/k/201610/20161008150136_22731.png','http://oss2.zhongou.org.cn/web/p/k/201610/20161008150650_46124.png','http://oss2.zhongou.org.cn/web/p/k/201610/20161008150813_62939.png',],
      currentIndex:0,
      font:['李克强总理接见中欧医管监事长陈亚光先生','温家宝总理亲切接见中欧医管监事长陈亚光先生','刘吉部长接见中欧医管董事长袁彦龙先生','陈竺部长接见中欧医管监事长陈亚光先生','孙隆春副部长接见中欧董事长袁彦龙先生','刘延东副总理接见中欧医管监事长陈亚光先生','中国医师协会张雁灵会长接见袁彦龙先生']
    }
  },
  mounted(){
    this.changePandT(0)
  },
  methods:{
    changePandT(index){
      
          this.currentIndex = index;

      let content0 = this.font[index];

      let title0 = this.font[index];

      this.textRight.content = content0;

      this.textRight.title = title0;

      this.textRight.index = index;

    },
  }
};
</script>
<style scoped lang='less'>
.pages {
  width: 1229px;
  background-color: #fff;
  margin-top: -20px;
  height: 638px;
  h3 {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 34px;
    color: #333333;
    padding: 28px 0 28px 25px;
    border-bottom: 1px solid #eee;
  }
  h2 {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 51px;
    color: #333333;
    // margin-top: 30px;
    // padding-top: 100px;
    text-align: center;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
::v-deep .el-carousel__container {
  width: 600px;
  height: 400px;
  margin: 30px auto;
}
.textRightBox{
  margin-bottom: 20px;
  .p2{
    text-align: center;
    font-size: 21px;
  }
}
</style>
<template>
  <el-dialog :visible.sync="dialogVisible" width="500px" title="账号登录" append-to-body >
	  <el-card class="box-card">
	    <div  class="text item">
	      <!-- 登录表单 -->
	      <el-form ref="loginForm" :model="loginForm" :rules="rules">
	        <el-form-item label="身份证号" prop="username">
	          <el-input v-model="loginForm.username" placeholder="请输入身份证号"></el-input>
	        </el-form-item>
	        <el-form-item label="密   码" prop="password">
	          <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
	        </el-form-item>
	        <el-form-item style="text-align: center;">
	          <el-button type="primary" class="btn" @click="handleLogin">登  录</el-button>
	        </el-form-item>
	      </el-form>
	    </div>
	  </el-card>
    
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getToken, setToken, setExpiresIn, removeToken } from '@/utils/auth'
import {
		login,
	} from "@/api/login.js";
 import Cookies from "js-cookie";


export default {
  data() {
    return {
	  loading: false,
      loginForm: {
        username: '',
        password: '',
		rememberMe: false,
		code: "",
		uuid: ""
      },
      rules: {
        username: [{ required: true, message: '请输入身份证号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    };
  },
  computed: {
    ...mapState('user', ['loginDialogVisible']),
    dialogVisible: {
      get() {
        return this.loginDialogVisible;
      },
      set(value) {
        this.setLoginDialogVisible(value);
      }
    }
  },
  created() {
    this.getCookie();
  },
  methods: {
    ...mapActions('user', ['setLoginDialogVisible', 'login']),
	getCookie() {
	  const username = Cookies.get("username");
	  const password = Cookies.get("password");
	  const rememberMe = Cookies.get('rememberMe')
	  this.loginForm = {
	    username: username === undefined ? this.loginForm.username : username,
	    password: password === undefined ? this.loginForm.password : decrypt(password),
	    rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
	  };
	},
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, {
              expires: 30
            });
            Cookies.set("password", encrypt(this.loginForm.password), {
              expires: 30
            });
            Cookies.set('rememberMe', this.loginForm.rememberMe, {
              expires: 30
            });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          }
          this.$store.dispatch("user/Login", this.loginForm).then(() => {
            this.$message.success("登录成功！")
			this.dialogVisible = false; // 关闭对话框
			// 刷新当前页面
			location.reload();
			
			//获取用户详情
		   this.$store.dispatch('user/GetInfo').then(() => {
			 
			}).catch(err => {
			   Message.error(err)
			  })
			
          }).catch(() => {
            this.loading = false;
            if (this.captchaEnabled) {
              this.getCode();
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped>
	.btn {
		background-color: rgb(24, 144, 255);
		color: #fff;
		width: 100%;
		font-size: 16px !important;
	}
	
</style>